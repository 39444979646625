
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

import { PessoaService, UsuarioService } from "@/core/services/cadastros";
import { EmpresaService } from "@/core/services/compras/EmpresaService";
import { OrcamentoService } from "@/core/services/compras/OrcamentoService";

@Component
export default class CfgPedidoCompra extends Vue {
  item: {
    id: number;
    fornecedorId: number;
    usuarioId:number;
    orcamentoId:number;
    empresaId: number;
    dataInicial: string;
    dataFinal: string;
    dataEntregaFinal: string;
    dataHoraInicial:string;
    dataHoraFinal:string
  } = {
    id: 0,
    fornecedorId: 0,
    usuarioId:0,
    orcamentoId:0,
    empresaId: 0,
    dataInicial: "",
    dataFinal: "",
    dataEntregaFinal: "",
    dataHoraInicial: "",
    dataHoraFinal: ""
  };

  valid = true;

  fornecedores:any = [];
  empresas:any = [];
  usuarios:any = [];
  orcamentos:any = [];

  mounted() {
    
    const pessoaService = new PessoaService();
    pessoaService.ListarTudo().then(
    (res) => {
      this.fornecedores = res.data.items;
      this.fornecedores.sort((x,y) => (x.nome > y.nome) ? 1 : ((y.nome > x.nome) ? -1 : 0))
    });

    const empresaService = new EmpresaService();
    empresaService.ListarTudo().then(
    (res) => {
      this.empresas = res.data.items;
       this.empresas.sort((x,y) => (x.nome > y.nome) ? 1 : ((y.nome > x.nome) ? -1 : 0))
    });
    new UsuarioService().ListarTudo().then(
      res => {
        this.usuarios = res.data.items
         this.usuarios.sort((x,y) => (x.login > y.login) ? 1 : ((y.login > x.login) ? -1 : 0))
        }
    )
    new OrcamentoService().ListarTudo().then(
      res => {
        this.orcamentos = res.data.items
         this.orcamentos.sort((x,y) => (x.id > y.id) ? 1 : ((y.id > x.id) ? -1 : 0))
        }
    )
  }

  Visualizar() {
    if((!this.item.dataInicial || !this.item.dataFinal) && (!this.item.dataHoraInicial || !this.item.dataHoraFinal)){
        this.$swal("Aviso", "Obrigatório preencher início e fim de pelo menos uma das datas.", "warning");
        return true;
    }
    let routeLink = this.$router.resolve({name: 'relPedidoCompra',
      query:{
        id: this.item.id.toString(),
        fornecedorId: this.item.fornecedorId ? this.item.fornecedorId.toString() : '0',
        empresaId: this.item.empresaId ? this.item.empresaId.toString() : '0',
        orcamentoId:this.item.orcamentoId ? this.item.orcamentoId.toString() : '0',
        usuarioId:this.item.usuarioId ? this.item.usuarioId.toString() : '0',
        datainIcial: this.item.dataInicial,
        dataFinal: this.item.dataFinal,
        dataEntregaFinal: this.item.dataEntregaFinal,
        dataHoraInicial:this.item.dataHoraInicial,
        dataHoraFinal: this.item.dataHoraFinal
      }
    }); 

    window.open(routeLink.href, '_blank');

  }
}
